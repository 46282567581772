/*
 * Seeds
 *
 * Use this file to populate an empty Firestore or reset the content
 * of the Firestore.
 */



const seeds = {
    en: {
        "engage.Contact.prompt.message": "my message",
        "engage.animation.icon1": "Eat",
        "engage.Contact.prompt.role": "and I'm a",
        "engage.Vision.body": "<p>The University of New Mexico has commenced its Integrated Campus Plan process and retained Sasaki Associates to lead a multidisciplinary team to assist with the development of the plan. The Integrated Campus Plan process is grounded in the University’s Strategic Plan <a href='' rel='noopener noreferrer' target='_blank'>UNM 2040: Opportunity Defined</a> and other previous planning efforts and will assist the University in physically translating its academic mission and strategic vision. The Integrated Campus Plan will focus on the facilities, grounds, and physical assets of the institution to provide a “road map” to fulfill the vision.</p>",
        "engage.privacy": "We respect your privacy and do not share your information with anyone.",
        "engage.Contact.prompt.email": "and my email address is",
        "engage.Contact.prompt.name": "Hi, my name is",
        "engage.Process.title": "The planning process",
        "engage.Contact.error": "Ooops! There was an error.",
        "engage.Welcome.title": "Let's envision the future of UNM campus together.",
        "engage.Vision.subtitle": "Vision & About",
        "engage.welcome.body": "<p>Keep updated on the Guelph Innovation District development process, and get involved by sharing your ideas below. Be sure to Register, and check back periodically for more updates.</p>",
        "engage.Resources.subtitle": "Resources & Updates",
        "engage.Vision.historical.title": "In the foreground sits Hodgin Hall, the first building built at UNM",
        "engage.animation.icon2": "Gather",
        "engage.Contact.thanks": "Thanks!",
        "engage.Contact.title": "Get involved today",
        "engage.welcome.title": "Guelph Innovation District",
        "engage.Process.body": "This is a new paragraph section.",
        "engage.animation.icon3": "Walk",
        "engage.animation.prompt2": "How do you get around ...",
        "engage.Contact.submit": "Send",
        "engage.Process.items": [
          {
            "duration": "September '22 - November '23",
            "title": "On-Going Engagement",
            "body": "You are at the heart of this story. At every point in the project, we will reach out to you and others across UNM’s many communities to ask for your insight, reactions, and feedback both virtually and in-person. This website will always have the latest news on how you can share your voice! Sign up to get updates.[link to sign up form at the bottom of the page]"
          },
          {
            "duration": "September 2022 - December 2022",
            "name": "Phase 01",
            "title": "Discovery and Analysis",
            "body": "During the first phase, the Sasaki team will gather and integrate past campus plans and studies and conduct a thorough site visit to understand current conditions and key objectives for the Integrated Campus Plan. Key phase one developments will include a series of meetings with the core UNM planning team (core team) and facilities management, collaborative defining of guiding principles, and stakeholder interviews with members of the UNM community from executive leadership to regents, administrators, faculty, staff, students, and community partners."
          },
          {
            "duration": "January 2023 - July 2023",
            "name": "Phase 02",
            "title": "Development and Drafting of the Plan",
            "body": "During the first phase, the Sasaki team will gather and integrate past campus plans and studies and conduct a thorough site visit to understand current conditions and key objectives for the Integrated Campus Plan. Key phase one developments will include a series of meetings with the core UNM planning team (core team) and facilities management, collaborative defining of guiding principles, and stakeholder interviews with members of the UNM community from executive leadership to regents, administrators, faculty, staff, students, and community partners."
          },
          {
            "duration": "August 2023 - November 2023",
            "name": "Phase 03",
            "title": "Implementation",
            "body": "Based on the input our team gathered throughout Phase 2, we will develop a preferred plan. This will illustrate key ideas and recommendations of the selected plan and its supporting strategies in greater detail. The planning team will also identify priority projects to help the University develop an approach to phasing and implementation of future projects. The draft ICP plan will be reviewed by UNM’s core team and any public review the University deems necessary."
          }
        ],
        "engage.Contact.subtitle": "Contact & Share Your Ideas",
        "engage.resources": [
          {
            "image": "",
            "subtitle": "Link to Website",
            "action": "link",
            "title": "UNM 2040"
          }
        ],
        "engage.title": "University of New Mexico Integrated Campus Plan",
        "engage.animation.prompt1": "Where do you ...",
        "calendar.button": "Share your ideas",
        "engage.Process.subtitle": "Process & Timeline",
        "engage.Welcome.body": "Share your ideas and experiences to build the future of the University of New Mexico",
        "engage.engagement.title": "Engagement Survey",
        "engage.Vision.title": "The Future of UNM",
        "engage.Resources.body": "",
        "engage.animation.icon4": "Transit",
        "engage.engagement.button": "Share Your Ideas",
        "engage.Vision.historical.image": "",
        "engage.animation.icon5": "Live",
        "engage.Resources.title": "Project resources",
        "engage.banner": "Share your ideas and experiences to build the future of the University of New Mexico",
        "engage.Vision.present.image": "",
        "Vision.scroll": [
          {
            "title": "A New Hub on Ontario’s Innovation Corridor",
            "content": "<p><br></p>"
          },
          {
            "image": "https://firebasestorage.googleapis.com/v0/b/orvsp-369c0.appspot.com/o/uploads%2Fd93d0dddfef4ed99c669f57c70f44f2f?alt=media&token=ba30ba61-ad6a-4b30-9fe6-ec7c844b64ba",
            "title": "Transforming the City of Guelph",
            "content": "<p>The most significant development in Guelph's history.</p>"
          },
          {
            "image": "https://firebasestorage.googleapis.com/v0/b/orvsp-369c0.appspot.com/o/uploads%2F003f6dda28ab0d77d8f7e6293fd0b8be?alt=media&token=b724f592-c7f9-4011-bb96-51fbc0ee642f",
            "title": "Guelph’s Innovation Triangle",
            "content": "<p>Locally, the Guelph Innovation District will form the third point in an innovation triangle that includes Downtown Guelph, with its emerging businesses and vibrant commercial activities, and the university of Guelph, with its academic and research programs supporting innovation at GID.</p>"
          }
        ],
        "engage.Vision.present.title": "Recently updated Smith Plaza in front of Zimmerman Library"
      }
};

export default seeds;
