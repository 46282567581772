const firebaseConfig = {
  apiKey: "AIzaSyCse-Lx_JvbY_An5sR9Trveg7vxBFZdnZU",
  authDomain: "guelphinnovationdistrict.firebaseapp.com",
  databaseURL: "https://guelphinnovationdistrict-default-rtdb.firebaseio.com",
  projectId: "guelphinnovationdistrict",
  storageBucket: "guelphinnovationdistrict.appspot.com",
  messagingSenderId: "380962824744",
  appId: "1:380962824744:web:1e32252ff61018b332cb1d"
};
export default firebaseConfig;
